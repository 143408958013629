.ddh {
  display: none;
}

@media screen and (min-width: 1024px) {
  .ddh {
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .image {
      margin: 0;
      margin-left: 80px;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .sectors {
      background-color: #ef3439;
      color: white;
      display: flex;
      align-items: center;
      padding: 7px 20px;
      border-radius: 7px;
      img {
        margin-right: 10px;
        width: 16px;
      }
      cursor: pointer;
    }
  }
}
