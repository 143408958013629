.acts {
  background: transparent linear-gradient(143deg, #ef3439 0%, #781a1d 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 13px 37px;
  border-radius: 10px;
  margin: 15px 10px 26px;

  .acts__title {
    margin: 13px 0 32px 0;
  }
  .acts__details {
    border-bottom: solid white 0.5px;
    margin-bottom: 25px;
    // border: solid red 1px;
    p {
      width: 90%;
      cursor: pointer;
      text-transform: uppercase;
    }
    p:hover {
      text-decoration: underline;
    }
    h5 {
      margin: 0;
      margin-bottom: 6px;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 768px) {
  .acts {
    margin: 0;
    padding: 20px 20px;
    height: 100%;
    // border: solid red 1px;
    display: flex;
    flex-direction: column;

    .acts__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // border: solid red 1px;
    }
    h3 {
      margin: 0;
      margin-bottom: 10px;
    }
    .acts__details {
      margin-bottom: 10px;
      p {
        width: 100%;
        font-size: 1em;
        margin: 10px 0;
      }
    }
    .acts__title {
      margin: 0;
    }
  }
}
