.newsheader {
  display: none;
}

.newsheader__home {
  display: flex;
  margin: 15px;
  .newsheader__image {
    img {
      height: 40px;
      width: auto;
      cursor: pointer;
      max-width: 70%;
      // border: solid red 1px;
    }
  }
  .newsheader__options {
    flex-grow: 1;
    max-width: 500px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // border: solid red 1px;
  }
  .newsheader__option {
    cursor: pointer;
  }
  .newsheader__option:not(:last-of-type) {
    margin-right: 20px;
  }

  .newheader__option--bg {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border-radius: 7px;
    background-color: #ef3439;
    color: white;
    height: max-content;
    img {
      margin-right: 10px;
      width: 16px;
    }
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  .newsheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    color: black;
    margin: 20px auto;
    width: 90%;
    // border: solid red 1px;
  }
  .newsheader__image {
    img {
      height: 50px;
      cursor: pointer;
      max-width: unset !important;
      // border: solid red 1px;
    }
  }
  .newsheader__options {
    flex-grow: 1;
    max-width: 500px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // border: solid red 1px;
  }

  .newsheader__option:not(:last-of-type) {
    cursor: pointer;
    margin-right: 40px !important;
    // border: solid red 1px;
  }

  .newheader__option--bg {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border-radius: 7px;
    background-color: #ef3439;
    color: white;
    height: max-content;
    img {
      margin-right: 10px;
      width: 16px;
    }
    cursor: pointer;
  }
}
