.accountform {
  margin: 10px;
  padding: 13px 20px;
  background-color: white;
}

.form__control {
  margin-bottom: 35px;
}
.accountform__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .accountform__header--main {
    display: flex;
    h3 {
      margin: 0;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        color: #ef3439;
      }
    }
    img {
      width: 15px;
      position: relative;
      top: -1px;
      cursor: pointer;
    }
  }
}
.accountform__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 60px 0;

  .disabled {
    opacity: 0.5;
  }

  button {
    padding: 10px 20px;
    border: 0;
    outline: 0;
    border-radius: 50px;
    font-weight: bold;
  }
  button:first-of-type {
    background-color: white;
    color: black;
    border: 1px solid #707070;
  }
  button:last-of-type {
    background-color: #ef3439;
    color: white;
  }
}
.form__changepassword {
  margin-left: auto;
  font-size: small;
  color: #ef3439;
  font-weight: bold;
  cursor: pointer;
}
.customModal {
  // border: solid red 1px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}
.changepassword__modal {
  margin: 20px 20px;
  // border: solid red 1px;
  .disabled {
    opacity: 0.5;
  }

  .isDirty {
    border-color: #ef3439;
  }

  button {
    padding: 10px 20px;
    border: 0;
    outline: 0;
    border-radius: 50px;
    font-weight: bold;
    background-color: #ef3439;
    color: white;
    margin-top: 30px;
  }

  h3 {
    margin-bottom: 50px;
    font-size: 1.5em;
  }
}

#modal__closeicon {
  display: none;
}

@media screen and (min-width: 768px) {
  .accountform {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 20px 20px;
    margin: 0px;
    // border: solid red 1px;
  }
  .account__segments {
    display: flex;
    justify-content: space-between;

    & > div {
      flex-basis: 45%;
    }
  }
  .accountform__footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px 0px;
    padding: 0 20px;

    button {
      padding: 16px 40px;
      border: 0;
      outline: 0;
      border-radius: 50px;
      font-weight: bold;
    }
    button:first-of-type {
      background-color: white;
      color: black;
      border: 1px solid #707070;
      margin-right: 70px;
    }
    button:last-of-type {
      background-color: #ef3439;
      color: white;
    }
  }
  .form__control {
    margin-bottom: 6px;
  }
}
