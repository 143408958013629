.podcast__image{
    width: 100%;
    img{
        width: 100%;
        height: auto;
        max-height: 300px;
    }
    h1{
        padding: 20px;  
    }
    span{
        color:#ef3439;
        padding: 20px; 
        font-weight: bold;
        cursor: pointer;
    }
    span:hover{
        text-decoration: underline;
    }
}