.sectors__container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  h3 {
    font-size: xx-large;
    color: #1e1e1e;
    margin: 16px 0;
  }
  p {
    margin-bottom: 25px;
  }
  .sectors__gallery {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    margin: 0 auto;
  }

  .sectors__sector {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    height: 300px;
    margin-bottom: 40px;

    .sector__main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
      cursor: pointer;
      box-sizing: border-box;
      // border: solid red 1px;
      padding: 0px;
      background-color: #ffffff;
      background-size: 100% 100%;
      border: solid 15px #ffffff;
      opacity: 0.82;
      font-weight: bold;
      margin: 0 auto;
      img {
        width: 27px;
        cursor: pointer;
      }
    }
  }

  .real-estate-and-infrastructure {
    background-image: url("../../assets/img/real-estate.png");
    background-size: 100% 100%;
    background-repeat: repeat;
    // border: solid red 1px;
  }
  .fast-moving-consumer-goods {
    background-image: url("../../assets/img/fast-moving.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .financial-service {
    background-image: url("../../assets/img/financial-services.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .health-and-pharmaceuticals {
    background-image: url("../../assets/img/health-img.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .public-sector {
    background-image: url("../../assets/img/public.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .technology-media-entertainment {
    background-image: url("../../assets/img/tech-newsletter.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 600px) {
  .sectors__container {
    .sectors__gallery {
      // display: grid;
      // grid-template-columns: 45% 45%;
      // grid-template-rows: 30% 30% 30%;
      column-gap: 10%;
      row-gap: 100px;
      flex-grow: 1;
      // align-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      justify-content: center;
      // column-gap: 5%;
      row-gap: 50px;
      width: 100%;

      .sectors__sector {
        // height: 350px;
        max-width: 300px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .sectors__container {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: calc(100vh - 105px);
    max-height: 800px;
    width: 100%;

    h3 {
      margin: 0;
      margin-bottom: 16px;
    }

    .sectors__sector {
      display: flex;
      align-items: flex-end;
      width: 100%;
      padding: 0;
      margin: 0;
      // height: unset;

      .sector__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 100%;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0px;
        background-color: #ffffff;
        background-size: 100% 100%;
        border: solid 15px #ffffff;
        opacity: 0.82;
        font-weight: bold;
        margin: 0 auto;
        img {
          width: 27px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .sectors__container {
    .sectors__gallery {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      justify-content: center;
      column-gap: 5%;
      row-gap: 10%;
      width: 100%;

      .sectors__sector {
        width: 30%;
        height: 45%;
      }
    }
  }
}
