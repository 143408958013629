.laws {
  & > img {
    width: 100%;
    height: auto;
    max-height: 400px;
  }
  .loader {
    position: relative;
  }
}
.laws__content {
  padding-top: 80px;
  border-top: 2px solid #707070;
  display: flex;
  flex-direction: column;
  & > .lawsPaginate__container {
    flex-grow: 1;
  }
  width: 90%;
  margin: 20px auto;
}

.laws__searchrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  .detail {
    font-size: x-large;
  }
  .searchbox {
    border: solid #1e1e1e 1px;
    border-radius: 10px;
    width: 250;
    padding: 5px;
    display: flex;
    margin-top: 20px;
    form {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    img {
      height: 20px;
      cursor: pointer;
    }
    input {
      border: none;
      outline: none;
      padding: 0 5px;
      flex-grow: 1;
    }
  }
  margin: 30px 0;
}

@media screen and (min-width: 768px) {
  .laws {
    & > img {
      height: auto;
      max-height: 400px;
    }
  }
  .laws__content {
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 1800px;
    margin: 20px auto;
    & > .lawsPaginate__container {
      margin-right: 60px;
    }
  }

  .laws__searchrow {
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 1800px;
    margin: 30px auto;
    padding: 0;
    .searchbox {
      margin: 0;
      justify-content: space-between;
      width: 250px;
    }
  }
}
