.pagination {
  width: 90%;
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  color: #ef3439;
  margin: 20px auto;
  justify-content: flex-end;

  li {
    margin: 5px;
    cursor: pointer;
    font-size: 0.9em;
    padding: 6px;
  }
  li a:focus {
    outline: none;
  }

  .active {
    border-radius: 5px;
    background-color: #ef3439;
    color: white;
  }
  .disClass {
    opacity: 0.5;
    cursor: text;
  }
}

@media screen and (min-width: 768px) {
  .paginate__container {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 5%;
    width: 80%;
    max-width: 2000px;
    margin: 30px auto;
  }

  .pagination {
    width: 80%;
    max-width: 1800px;
  }
}

@media screen and (min-width: 1024px) {
  .paginate__container {
    grid-template-columns: 30% 30% 30%;
    column-gap: 5%;
  }
}
@media screen and (min-width: 1200px) {
  .paginate__container {
    grid-template-columns: 22% 22% 22% 22%;
    column-gap: 4%;
    // border: solid red 1px;
  }
}
