.socials {
  display: flex;
  font-size: x-small;
  margin-bottom: 15px;
  .socials__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    img {
      width: 30px;
      height: auto;
      margin-bottom: 3px;
    }
  }
}

@media screen and (min-width: 768px) {
  .socials {
    flex-direction: column;
  }
  .socials__item {
    margin-right: unset;
    margin-bottom: 10px;
  }
}
