.recent {
  border-radius: 10px;
  background: transparent url("../../assets/img/Rectangle 16.svg") 0% 0%
    no-repeat;
  background-size: cover;
  padding: 21px;
  color: white;
  margin: 15px 10px 16px 10px;
  h3 {
    margin-bottom: 26px;
  }
  p {
    margin-bottom: 26px;
    text-transform: uppercase;
    cursor: pointer;
  }
  p:hover {
    text-decoration: underline;
  }
}
@media screen and (min-width: 768px) {
  .recent {
    margin-top: 0;
    margin: 0;
    padding: 5px 20px;
    margin-bottom: 18.5px;
    flex-grow: 1;
    h3 {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    p {
      font-size: 1em;
      margin-bottom: 10px;
    }
  }
}
