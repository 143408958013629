.sidebar {
  display: none;
}
@media screen and (max-width: 1023.9px) {
  body.sidebar__opened {
    overflow: hidden;
  }
  .sidebar {
    display: block;
    background-color: white;
    width: 100%;
    font-size: 1.3em;
  }
  .sideNav {
    height: 100vh;
    width: 0;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    border-top: solid rgba(black, 0.1) 0.5px;
    transition: width ease 0.6s;
    background: rgba(white, 1);
    display: flex;
    flex-direction: column;
    z-index: 10000;
    opacity: 1;
  }

  .sideNav.open {
    width: 100%;
  }

  .menu {
    cursor: pointer;
  }

  .sidebarHeader {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: 0 auto;

    .menu__img {
      height: 30px;
      width: auto;
    }

    .logo__img {
      height: 40px;
      width: auto;
      position: relative;
      left: -11px;
    }
  }

  .sideNav__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 85%;
    margin: 15px auto;
    margin-bottom: 40px;
    .close__icon {
      position: relative;
      left: -8.5px;
      width: 45px;
      height: auto;
    }
    .user__icon {
      position: relative;
      left: -30px;
      width: 70px;
      border-radius: 50%;
    }
  }

  .nav__list {
    border-left: solid 3px #ef3439;
    display: none;
    width: 85%;
    margin: 0 auto;
    padding: 4px 10px;

    .nav__item {
      display: block;
      margin: 23px 0;
      .cheveron__right {
        position: relative;
        top: 3px;
      }
    }
    .nav-item:hover {
      font-weight: bold;
    }
    .nav__item:first-of-type {
      margin-top: 0;
    }
    .nav__item:last-of-type {
      margin-bottom: 0;
    }
  }
  .show__list {
    display: block;
  }

  .nav__footer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 85%;
    margin: 0 auto;
    margin-top: 10px;

    .footer__detail {
      margin-left: 8px;
      position: relative;
      color: #ef3439;
      font-weight: bold;
      top: -6px;
    }
  }
  .footer__main {
    display: none;
  }
  .show__footer {
    display: block;
  }
}
