.calendar {
  background-color: white;
  max-width: 100%;
  margin: 38px 10px;
  padding: 20px;
  border-radius: 10px;
  // font-size: x-small;
  h3 {
    margin: 0;
    margin-bottom: 15px;
  }
}

.cld-main {
  max-width: 100%;
}

.eventday {
  color: inherit !important;
  font-weight: normal !important;
  cursor: pointer;
  position: relative;
}
.eventday:after {
  position: absolute;
  content: " \25CF";
  font-size: 10px;
  color: #ef3439;
}
.eventday:focus {
  outline: none;
}

.cld-day.today .cld-number {
  background: #496ddb;
  margin: 0 auto;
}
.cld-main svg {
  fill: #496ddb;
}
.cld-title {
  //hover message
}

@media screen and (min-width: 768px) {
  .calendar {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    max-width: unset;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    h3 {
      margin: 0;
      margin-bottom: 10px;
    }
    min-width: 400px;

    & > div {
      flex-grow: 1;
      // border: solid red 1px;
      display: flex;
      align-items: center;
    }
  }
}
