@import "../../assets/styles/colors";

.intro {
  height: calc(75vh - 60px);
  width: 100%;
  background: url("../../assets/img/landing-page.png"),
    linear-gradient(rgba(black, 0.5), rgba(black, 0.5));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    color: white;
    background-color: $accent;
    border: none;
    outline: 0;
    width: 118px;
    height: 38px;
    border-radius: 100px;
    font: normal normal bold 13px/15px Raleway;
  }
  h3 {
    font-size: 2.1em;
    margin-bottom: 0;
    max-width: 90%;
  }
  p {
    font-size: 1.2em;
    max-width: 70%;
  }
  & > * {
    margin-bottom: 20px;
    margin-left: 6.5%;
  }
}

@media screen and (min-width: 1280px) {
  .intro {
    background: url("../../assets/img/landing-page.png"),
      linear-gradient(rgba(black, 0.5), rgba(black, 0.5));
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }
}

@media screen and (min-width: 768px) {
  .intro {
    & > * {
      margin-left: 6.5%;
      max-width: 853px;
    }
    p {
      max-width: 400px;
    }
    h3 {
      max-width: 300px;
    }
  }
}
