.cld-main {
  width: 100%;
}
.cld-main a {
  color: #0080ff;
}
.cld-main svg {
  fill: #0080ff;
}
.cld-datetime {
  position: relative;
  display: flex;
  align-items: center;
  width: 66%;
  min-width: 100px;
  max-width: 350px;
  margin: auto;
  overflow: hidden;
}
.cld-datetime .today {
  position: relative;
  float: left;
  width: calc(100% - 40px);
  margin: auto;
  text-align: center;
}
.cld-nav {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
.cld-nav:hover {
  cursor: pointer;
}
.cld-nav:hover svg {
  fill: #005eff;
}
.cld-rwd {
  float: left;
}
.cld-fwd {
  float: right;
}
.cld-nav svg:hover {
}
.cld-labels,
.cld-days {
  padding-left: 0;
}
.cld-labels {
  margin: 5px 0;
}
.cld-label,
.cld-day {
  box-sizing: border-box;
  display: inline-block;
  width: 14.28%;
  text-align: center;
  font-size: small;
}
.cld-day {
  border: 1px solid #eee;
}
.cld-day.today .cld-number {
  background: #0080ff;
  color: #fff;
  border-radius: 7px;
}
.cld-day.disableDay {
  opacity: 0.5;
}
.cld-day.nextMonth,
.cld-day.prevMonth {
  opacity: 0.33;
}
.cld-number {
  position: relative;
  margin: 0;
  padding: 10px;
}
@media screen and (min-width: 1024px) {
  .cld-number {
    padding: 3px;
  }
}
.cld-title {
  position: absolute;
  z-index: 5;
  display: none;
  top: 35px;
  left: 0;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
}
.cld-number:hover .cld-title {
  display: flex;
  width: max-content;
  max-width: 200px;
  text-align: left;
  line-height: 1.5;
}
.cld-title::before {
  content: "";
  position: absolute;
  top: -7.5px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
  border-bottom: 7.5px solid #ccc;
}
.cld-number.eventday {
  font-weight: bold;
  color: #0080ff;
}
.cld-number.eventday:hover {
  cursor: pointer;
  background: #eee;
}
.today .cld-number.eventday:hover {
  background: #005eff;
}
