.blog {
  background-color: #f3eeee;
  min-height: 100vh;
  padding: 20px;
}

.blog__heading {
  display: flex;
  flex-direction: column;
  font-size: x-large;
  margin-bottom: 50px;
  img {
    width: 100%;
  }
}
.blog__search {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: solid #1e1e1e 1px;
  margin-bottom: 50px;

  .blog__searchText {
    font-size: x-large;
  }
}
.blog__searchbox {
  border: solid #1e1e1e 1px;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background: white;
  img {
    height: 20px;
    cursor: pointer;
  }
  input {
    border: none;
    outline: none;
    padding: 0 5px;
    flex-grow: 1;
  }
}

@media screen and (min-width: 768px) {
  .blog__heading {
    margin-bottom: 100px;
    width: 80%;
    max-width: 1800px;
    margin: 0px auto 100px;
    flex-direction: row;
    .blog__headImage {
      flex-grow: 1;
    }
    .blog__headText {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 5px solid #707070;
    }

    p {
      padding-left: 25px;
      max-width: 360px;
      font-size: 1.1em;
    }
    img {
      width: 80%;
      max-width: 700px;
    }
  }
  .blog__search {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
    width: 80%;
    max-width: 1800px;
    margin: 60px auto;
  }
  .blog__searchbox {
    margin: 0;
    justify-content: space-between;
    width: 250px;
  }
}
