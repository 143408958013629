.paginate__blog {
}

@media screen and (min-width: 768px) {
  .paginate__blog {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 5%;
    width: 80%;
    max-width: 1800px;
    margin: 60px auto;
  }
}
