.dashboard {
  background-color: #f3eeee;
}
.dashboard__content {
  min-height: 100vh;
}

@media screen and (min-width: 768px) {
  .dashboard__content {
    min-height: calc(100vh - 111.5px);
  }
  .dashboard {
    background-color: white;
  }
}

@media screen and (min-width: 1024px) {
  .dashboard__header {
    margin-bottom: 5px;
    flex-grow: 0;
  }

  .dashboard {
    width: calc(100vw - 220px);
    margin-left: auto;
    background-color: #ffffff;
    padding: 0px 30px;
    z-index: 1000;
  }
}

@media screen and (min-height: 801px) {
  .dashboard__content {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // border: solid red 1px;
  }
}
