@media screen and (min-width: 768px) {
  .dashboardHome {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 85px);
    // border: solid red 1px;
  }

  .arc {
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-areas: "a rc";
    gap: 40px;
    margin-bottom: 20px;
    align-content: center;
    // border: solid red 1px;
  }
  .a {
    grid-area: a;
  }
  .rc {
    grid-area: rc;
    max-width: 400px;
    flex-direction: column;
    display: flex;
  }
  .r {
    // border: solid red 1px;
    flex-grow: 1;
    display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dashboardHome {
    padding: 20px;
  }
}

@media screen and (min-height: 932px) {
  .dashboardHome {
    padding-top: 10vh;
  }
}
