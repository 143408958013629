.newscard {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 10px;
  background-color: white;

  .newscard__img__container {
    img {
      width: 100%;
    }
    margin-bottom: 10px;
  }

  h4 {
    font-weight: bold;
    font-size: 1.2em;
    color: #806e6e;
    opacity: 1;
    margin: 0;
    margin-bottom: 20px;
  }
  span {
    font-size: 0.9em;
    color: #ef3439;
    opacity: 1;
  }
  p {
    font-size: 0.9em;
    color: #1e1e1e;
    opacity: 1;
    padding-bottom: 5px;
    width: 100%;
    line-height: 1.5;
  }
}

.newscard:hover {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  & h4 {
    color: #ef3439;
  }
}

@media screen and (min-width: 768px) {
  .newscard {
    margin: 30px 0;
    max-width: unset;
    height: 500px;
    overflow: hidden;
    padding-bottom: 15px;

    .newscard__img__container {
      img {
        width: 100%;
        height: 157px;
        object-fit: cover;
        // border: solid red 1px;
      }
      margin-bottom: 10px;
    }
  }

  .newscard__detail {
    // border: solid red 1px;
    display: flex;
    flex-direction: column;

    p {
    }
  }
}
