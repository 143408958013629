.thought__header {
  background: url("../../assets/img/thoughtleadership.png"), rgba(0, 0, 0, 0.5);
  height: 250px;
  background-size: 100% 100%;
  background-blend-mode: overlay;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  h3 {
    font-size: x-large;
    font-weight: bold;
  }
  & > div {
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
  }
}

.paginations {
  width: 90%;
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  color: #ef3439;
  margin: 20px auto;
  justify-content: flex-end;
  li {
    margin: 5px;
    cursor: pointer;
    font-size: 0.9em;
  }
  li a:focus {
    outline: none;
  }
}

@media screen and (min-width: 768px) {
  .thought__header {
    height: 350px;
    & > div {
      width: 80%;
      max-width: 1800px;
    }
  }
  .newsLetter__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    max-width: 1800px;
    margin: 30px auto;
  }
  .paginations {
    width: 80%;
    max-width: 1800px;
  }
}
@media screen and (min-width: 1024px) {
  .newsLetter__main {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1200px) {
  .newsLetter__main {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
