.feedback {
  display: flex;
  background-color: white;
  flex-direction: column;
  max-width: 350px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  .cancel {
    cursor: pointer;
  }
  .disabled {
    opacity: 0.5;
  }
  .feedback__content {
    padding: 0 20px;
  }
  p {
    width: 75%;
    font-size: 1em;
  }
  .feedback__header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ef3439;
    font-size: 1.2em;
    color: white;
    padding: 0 20px;
    margin-bottom: 30px;
  }
  textarea {
    display: block;
    min-height: 100px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
    padding: 20px;
    border: solid #f2f2f2 1px;
    border-radius: 5px;
    outline: 0;

    &:active,
    &:focus {
      border: solid #f2f2f2 2px !important;
    }
  }
  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    padding: 0 20px;
    button {
      background: #ef3439 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: white;
      border: none;
      outline: none;
      font-size: 1em;
      margin-left: auto;
      padding: 7px 20px;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
