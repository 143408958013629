@import "../../assets/styles/colors";

.mobile__show {
  display: block;
  width: 100%;
}
.mobile__hide {
  display: none;
}
.mobile__options {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
  }
  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }
}

.header {
  width: 100%;
  background-color: white;
  padding: 20px 0;
}

.header__content {
  width: 87%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img[alt="logo"] {
    width: 189px;
    height: auto;
    position: relative;
    left: -9px;
    cursor: pointer;
  }
  img[alt="menu"] {
    width: 37.29px;
    height: auto;
  }
}

.header__drop {
  width: 87%;
  margin: 0 auto;
  max-height: 0;
  position: relative;
  overflow-y: hidden;
  transition: max-height 1s ease;

  span {
    margin: 15px 0 15px 0;
    display: flex;
    align-items: center;
    color: $text-dark;
  }

  button {
    padding: 10px 20px;
    background-color: $accent;
    color: white;
    border: none;
    outline: none;
    font-size: 13px;
    border-radius: 100px;
    display: block;
    margin: 0 auto;
    margin-top: 25px;
    font-weight: bold;
  }
}
.icon-space {
  margin-left: 3px;
  position: relative;
  // top: 1px;
}

.drop__show {
  max-height: 100vh;
}

.options__desktop {
  display: none;
}
.no__display {
  display: none;
}

button {
  cursor: pointer;
}
.redify {
  color: #ef3439 !important;
}

@media screen and (min-width: 1024px) {
  .drop__show {
    display: none;
  }
  .header__drop {
    display: none;
  }
  .header {
    align-items: stretch;
    padding: 0;
  }
  .header__content {
    display: flex;
    align-items: stretch;
    padding: 0;

    img[alt="logo"] {
      width: auto;
      height: 50px;
      position: relative;
      left: -9px;
      cursor: pointer;
      margin: auto 0;
    }
  }

  .header__options {
    display: flex;
    align-items: stretch;
    color: $text-dark;
    img[alt="menu"] {
      display: none;
    }

    button {
      padding: 10px 20px;
      background-color: $accent;
      color: white;
      border: none;
      outline: none;
      font-size: 13px;
      border-radius: 100px;
      display: block;
      font-weight: bold;
    }
  }
  .options__desktop {
    display: flex;
    font-size: 0.98em;
    color: #444;
    font-weight: 400;

    .option__desktop {
      cursor: pointer;
      padding: 30px 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .option__desktop:hover {
      cursor: pointer;
      color: #ef3439;
    }

    .option__desktop:last-of-type {
      margin-left: 30px;
      position: relative;

      & > ul {
        display: none;
      }
    }

    .option__desktop:last-of-type:hover {
      margin-left: 30px;

      & > ul {
        display: block;
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 20px;
        list-style-type: none;
        align-self: flex-start;
        margin-top: 49.2px;
        background-color: white;
        font-weight: normal;
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);

        li {
          cursor: pointer;
          color: black;
        }
        li:first-of-type {
          margin-bottom: 15px;
        }
        li:hover {
          color: #ef3439;
        }
      }
    }

    span {
      // margin-left: 30px;
      // font-weight: bold;
      // border: solid red 1px;
      // cursor: pointer;
    }
  }
}
