.imageGallery {
  width: 100%;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10%;
    row-gap: 50px;
    justify-items: center;
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5%;
    row-gap: 50px;

    .webinar {
      grid-column: 2;
    }
  }
}
