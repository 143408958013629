.about {
  h3 {
    text-align: center;
    font: 1.5em;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: normal;
  }

  a {
    color: #ef3439;
    font-weight: bold;
  }
}

@media screen and (min-width: 768px) {
  .about {
    width: 80%;
    max-width: 600px;

    button {
      width: 115px;
      height: 41px;
      border-radius: 50px;
      font-size: 14px;
    }
  }
}
