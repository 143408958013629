@import "assets/styles/colors";

.App {
  min-height: 100vh;
}

* {
  -webkit-tap-highlight-color: transparent;
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;

  &:active {
    color: inherit;
  }
}
ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

.modal-video {
  border: none !important;
  outline: 0 !important;
  & > * {
    border: none !important;
    outline: 0 !important;
  }
}
