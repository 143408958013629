.animatednews {
  overflow-y: hidden;
  position: relative;
  transition: all 0.3s ease 0s;
  width: 100%;
  // max-width: 450px;
  min-height: 350px;
  margin-bottom: 50px;
  cursor: pointer;

  .animatednews__animate {
    background: #eb191e;
    opacity: 0.9;
    position: absolute;
    top: 77.5%;
    transition: all 1s ease 0s;
    background-size: cover;
    height: 90%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .animatednews__heading {
    color: white;
    font-size: 0.98em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    flex-basis: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // border: solid black 1px;
    flex-direction: column;
    text-align: center;
  }
  .animated__mobilearrow {
    align-self: flex-end;
    img {
      width: 30px;
      display: block;
      margin: 10px;
    }
  }

  .animatednews__details {
    font-size: 1em;
    flex-grow: 1;
    width: 85%;
    line-height: 1.5;
    color: white;
    margin: 0 auto;
    padding: 15px 5px;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 8px;
      width: 40px;
    }
  }
}

.animatednews.small__news {
  min-height: 240px;

  .animatednews__animate {
    top: 70%;
  }

  .animatednews__heading {
    flex-basis: 32.5%;
    // border: solid black 1px;
  }

  .animatednews__details {
    img {
      width: 25px;
    }
  }
}

.laws__bg {
  background: url("../../assets/img/inaki-del-olmo-NIJuEQw0RKg-unsplash.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.news__bg {
  background: url("../../assets/img/pexels-brotin-biswas-518543.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.thought__bg {
  background: url("../../assets/img/pexels-cem-saka-4210673.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.webinars__bg {
  background: url("../../assets/img/webinars.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 500px) {
  .animatednews {
    .animated__mobilearrow {
      img {
        margin: 5px;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .animatednews {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .animatednews {
    &:hover .imageGallery__heading {
      justify-content: flex-start;
      padding-left: 10px;
    }

    &:hover .animatednews__animate {
      top: 10%;
    }

    .animatednews__content {
      width: 100%;
    }
    .animated__mobilearrow {
      display: none;
    }
    .animatednews__heading {
      justify-content: center;
      align-items: center;
    }
  }
}
