.account__page {
  max-width: 400px;
  min-height: 100vh;
  margin: 10px auto;
  .account__usercard {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .account__page {
    display: flex;
    max-width: unset;
    width: 100%;
    margin: 0;
    margin-top: 5vh;
    padding: 20px;
    min-height: unset;
  }
  .account__accountform {
    flex-grow: 1;
  }
  .account__usercard {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .account__page {
    padding: 0px;
  }
}
