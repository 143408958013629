.homesectors__container {
  margin: 0 auto;
  max-width: 1350px;
  margin-top: 50px;

  & > h3 {
    text-align: center;
    font-size: 2.1em;
    margin: 70px auto 50px;
  }
  & > h3:after {
    content: " ";
    display: block;
    border: 2px solid #ef3439;
    width: 90px;
    margin: 0 auto;
    margin-top: 3px;
  }
  & > p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    line-height: 1.6;
    font-size: 1.2em;
    @media screen and (max-width: 768px) {
      font-size: 1em;
    }
  }

  & > h5 {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 1em;
    text-align: center;
    line-height: 1.6;
  }
}

.homesectors {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    justify-content: center;
    column-gap: 0;
    row-gap: 50px;
  }
}
