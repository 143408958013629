.newsletter {
  & > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
  }
  .loader {
    position: relative;
  }
}

.newsletter__searchrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  .detail {
    font-size: x-large;
  }
  .searchbox {
    border: solid #1e1e1e 1px;
    border-radius: 10px;
    width: 250px;
    padding: 5px;
    display: flex;
    margin-top: 20px;
    form {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    img {
      height: 20px;
      cursor: pointer;
    }
    input {
      border: none;
      outline: none;
      padding: 0 5px !important;
      flex-grow: 1;
    }
  }
  margin: 30px 0;
}
.strong__slug {
  &:hover {
    color: #ef3439;
  }
}

@media screen and (min-width: 768px) {
  .newsletter {
    & > img {
      height: calc(55vh - 60px);
      object-fit: cover;
      object-position: center;
      max-height: 500px;
    }
  }

  .newsletter__searchrow {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // border: solid red 1px;
    width: 80%;
    max-width: 1800px;
    margin: 30px auto;
    padding: 0;
    .searchbox {
      margin: 0;
      justify-content: space-between;
      width: 250px;
    }
  }
}
