@import "../../assets/styles/colors";

.array {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  & > * {
    max-width: 85%;
    text-align: center;
  }

  & > h3 {
    text-align: center;
    font-size: 2.1em;
    margin: 70px auto 35px;
    padding-top: 20px;
    @media screen and (max-width: 786px) {
      font-size: 1.7em;
    }
  }
  & > p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    font-size: 1.2em;
  }
  & > h5 {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
    margin-top: 20px;
    font-size: 1em;
    text-align: center;
  }
  .array__images {
    width: 100%;
  }
}

@media screen and (min-width: 786px) {
  .array {
    & > p {
      width: 70%;
      text-align: center;
    }
    & > h5 {
      width: 70%;
      margin-bottom: 40px;
      text-align: center;
    }
    .array__images {
      padding: 0 20px 0;
      max-width: 1200px;
    }
  }
}
