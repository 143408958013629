.tabs {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 25px;

  .tabs__circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: x-small;
    width: 30px;
    white-space: nowrap;
  }

  .tabs__circle span:first-child {
    border-radius: 50%;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 5px;
    height: 20px;
    width: 20px;
  }

  .tabs__hr {
    border-bottom: 1px solid #000000;
    position: relative;
    top: 10px;
    flex-grow: 1;
    height: 0;
  }
}
.special {
  position: relative;
  left: 8px;
}

.tabs__hr.activeNow {
  border: 1px solid #ef3439;
}

.tabs__circle.activeNow span:first-child {
  background-color: #ef3439;
  color: white;
  border: none;
}

@media screen and (min-width: 768px) {
  .tabs {
    width: 80%;
    margin-bottom: 35px;
  }
}
