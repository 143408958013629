.news__container {
  border-top: solid black 1px;
  width: 100%;
}

.news {
  display: flex;
  flex-direction: column;
  margin: 5px 10px 26px;
  padding: 20px;
  padding-top: 5px;

  .news__article {
    font-family: "Courier Prime", monospace !important;
    line-height: 1.7;
    margin-bottom: 50px;

    h4 {
      padding: 50px 0;
      width: max-content;
    }

    h4:hover {
      color: #ef3439;
      cursor: pointer;
    }
    .border__line {
      border-bottom: 1px solid #a6a6a6;
    }
  }
}
.news__header {
  display: flex;
  flex-direction: column;
  margin: 20px 0 50px;
  & > h3 {
    font-size: x-large;
    margin: 20px 0;
    width: 90%;
  }
  & > p {
    margin: 0;
  }
}

.news__download {
  display: flex;
  align-items: center;
  font-size: small;
  img {
    margin-right: 13px;
    width: 20px;
  }
  span {
    cursor: pointer;
  }
  span:hover {
    color: #ef3439;
  }
}
.news__breadcrumb {
  font-size: 1.5em;
  margin-bottom: 80px;
  margin-top: 50px;
  strong:hover {
    color: #ef3439;
  }
}

@media screen and (min-width: 768px) {
  .news__breadcrumb {
    margin-top: 0;
  }
  .news__container {
    border-top: solid black 1px;
    width: 100%;

    .news {
      width: max-content;
    }
    .news__header {
      display: flex;
      flex-direction: column;
      margin: 0px 0 50px;
      & > h3 {
        width: 85%;

        font-size: xx-large;
        // border: solid red 1px;
      }
      & > p {
        margin: 0;
      }
    }
  }
  .news {
    flex-direction: row;
    margin: 0px auto;
    padding: 0px;
    padding-top: 40px;
    width: 90%;
    // border: solid red 1px;
  }
  .news__main {
    display: flex;
    flex-grow: 1;
    padding: 0;
    max-width: 700px;
    // border: solid red 1px;
  }
  .news__update {
    position: relative;
    top: 90px;
    // border: solid red 1px;
    // margin: 0;
    margin-left: 100px;
  }
  .news__content {
    // margin-left: 30px;
  }
}
