.law__options {
  display: flex;
  font-size: small;
  color: #ef3439;
  font-weight: bold;
  align-items: center;

  img {
    height: 20px;
    margin-right: 5px;
  }
  & > div {
    display: flex;
    margin-left: 40px;
    align-items: center;
    cursor: pointer;
  }
  & > span {
    cursor: pointer;
  }
}

.law__component {
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-bottom: solid 3px #707070;
  // border: solid red 1px;

  h3 {
    text-transform: uppercase;
  }
}
