.preferences {
  margin: 15px 10px 16px 10px;

  h3 {
    font-size: xx-large;
    color: #1e1e1e;
    margin: 0;
    margin-bottom: 16px;
  }
  p {
    font-size: small;
    margin-bottom: 25px;
  }
  .preferences__options {
    background: white;
    margin: 0 auto;
    margin-top: 40px;
    padding: 20px 10px;
    width: 90%;
  }
  .preferences__option {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3eeee;
    text-align: center;

    & > span {
      margin-bottom: 20px;
    }
  }
  .preferences__footer {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
    margin-top: 40px;
    button {
      background: #ef3439 0% 0% no-repeat padding-box;
      border-radius: 50px;
      opacity: 1;
      padding: 13px 40px;
      border: none;
      color: white;
      outline: 0;
      font-size: small;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 768px) {
  .preferences {
    margin: 0;
    width: 100%;
    min-height: calc(100vh - 135px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    // border: solid red 1px;
    h3 {
      font-size: xx-large;
      color: #1e1e1e;
      margin: 0;
      margin-bottom: 0px;
    }
    p {
      font-size: small;
      margin-bottom: 20px;
      margin-top: 5px;
    }

    .preferences__options {
      background: white;
      margin-top: 10px;
      padding: 0px 10px;
      padding-bottom: 5px;
      width: 85%;
      max-width: 1400px;
      max-height: 900px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .preferences__option {
      width: 100%;
      padding: 0px 10% 0px 5%;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // border: solid red 1px;
      flex-grow: 1;
      margin: 10px 0;
      max-height: 104px;

      & > span {
        margin-bottom: 0px;
      }
    }

    .preferences__option:first-of-type {
      margin-top: 0;
    }
    .preferences__footer {
      margin-top: 10px;
      padding-right: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .preferences {
    padding: 0;
  }
}

.preferences {
  .disabled {
    opacity: 0.5 !important;
    cursor: text !important;
  }
}
