.formControl {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  div {
    display: flex;
    align-items: center;
  }
  span {
    margin-bottom: 10px;
  }
  .account {
    margin-bottom: 25px;
  }
  img {
    width: 17px;
  }
  border-bottom: 3px solid #707070;
  padding-bottom: 6px;
}
.account__formcontrol {
  font-size: 1em;
}
.required:after {
  content: "*";
  color: red;
}

input[data-style="formControl"] {
  border: none;
  outline: none;
  margin-left: 20px;
  width: 100%;
  font-size: 0.85em;
}

input[data-style="formControl"]:disabled {
  background-color: white;
}

.radioBtn__container {
  /* Customize the label (the container) */
  display: flex;
  margin-top: 10px;
  label:first-of-type {
    margin-right: 50px;
  }
  label {
    display: flex;
    align-items: center;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #707070;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: white;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #ef3439;
  }

  .container input:checked ~ .checkmark {
    background-color: #ef3439;
    border: none;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ef3439;
  }
}

@media screen and (min-width: 768px) {
  .formControl {
    // font-size: small;
    img {
      width: 19px;
    }
    .account {
      margin-bottom: 8px;
    }
  }
}

.radioBtn__container.textcursor .container {
  cursor: text;
}
