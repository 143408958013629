.contactus {
  display: flex;
  flex-direction: column;

  .contactus__header {
    background-image: url("../../assets/img/bg-contact.jpg");
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 200px;
    color: white;
    & > * {
      margin-left: 20px;
    }
  }

  section {
    padding: 0 10px 0 20px;

    h3:after {
      display: block;
      content: "";
      border-top: 1px solid #ef3439;
      width: 3rem;
      margin-top: 10px;
    }
  }
  .contactus__addresses {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-top: 30px;
    border-top: solid 0.1px black;
  }
}

@media screen and (min-width: 768px) {
  .contactus {
    .contactus__header {
      background-image: url("../../assets/img/bg-contact.jpg");
      background-color: rgba(0, 0, 0, 0.5);
      background-blend-mode: overlay;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      & > * {
        margin-left: 6.5%;
      }
    }

    section {
      padding: 0;
      width: 87%;
      margin: 0 auto;
    }
    .contactus__addresses {
      display: grid;
      grid-template-columns: 30% 30% 30%;
      column-gap: 5%;
      flex-direction: column;
      margin-top: 50px;
      padding-top: 30px;
      border-top: solid 0.1px black;
    }
  }
}
