.homesector {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
  height: 350px;
  cursor: pointer;

  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  .homesector__main {
    color: white;
    height: 100%;
    padding: 40px 30px;
    transition: height 0.3s ease-in-out 0.1s;
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;

      h2 {
        margin: 0px;
        margin-bottom: 25px;
        font-weight: normal;
        font-size: 1.4em;
      }
      p {
        margin: 0;
      }
    }
    h4 {
      margin: 0px;
      margin-bottom: 60px;
    }
  }

  .arrowDiv {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 40px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 90% 50%;
    background-image: url("../../assets/img/arrow.png");
    transition: background-position 0.5s ease-in-out 0s,
      height 0.3s ease-in-out 0.1s;
  }

  &:hover {
    .arrowDiv {
      height: 40px;
      background-position: 50px 50%;
    }
    .homesector__main {
      height: 310px;
    }
  }

  .energy {
    background: url("../../assets/img/real-estate.png"),
      linear-gradient(rgba(black, 0.5), rgba(black, 0.6));
    background-blend-mode: overlay;
    background-size: 100% 100%;
    background-repeat: repeat;
  }
  .fast-moving {
    background-image: url("../../assets/img/fast-moving.png"),
      linear-gradient(rgba(black, 0.5), rgba(black, 0.6));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
  }
  .financial {
    background-image: url("../../assets/img/financial-services.png"),
      linear-gradient(rgba(black, 0.5), rgba(black, 0.6));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
  }
  .health {
    background-image: url("../../assets/img/health-img.png"),
      linear-gradient(rgba(black, 0.5), rgba(black, 0.6));
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
  }
  // .public {
  //   background-image: url("../../assets/img/public.png");
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  // }
  .technology {
    background-image: url("../../assets/img/tech-newsletter.png"),
      linear-gradient(rgba(black, 0.5), rgba(black, 0.6));
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
  }
}

@media screen and (min-width: 768px) {
  .homesector {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1200px) {
  .homesector {
    margin-left: 0px;
    margin-right: 0px;

    .arrowDiv {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 5px;
      background-color: white;
      background-repeat: no-repeat;
      background-size: 30px;
      background-position: -10% 50%;
      background-image: url("../../assets/img/arrow.png");
      transition: background-position 0.5s ease-in-out 0s,
        height 0.3s ease-in-out 0.1s;
    }

    &:hover {
      .arrowDiv {
        height: 40px;
        background-position: 50px 50%;
      }
      .homesector__main {
        height: 310px;
      }
    }
  }
  .homesector:nth-of-type(2) {
    margin-left: 50px;
    margin-right: 50px;
  }
  .homesector:nth-of-type(4) {
    margin-right: 50px;
  }
}
