.usercard {
  display: flex;
  color: white;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  background: url("../../assets/img/usercard-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  padding: 30px 50px;
  text-align: center;
  p {
    font-size: 1em;
  }

  .usercard__title {
    font-size: 0.8em;
    font-weight: bold;
  }
  .usercard__gallery {
    position: relative;
    img:first-of-type {
      width: 120px;
      height: 120px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
    img:last-of-type {
      width: 29px;
      position: absolute;
      right: -3px;
    }
  }
  .usercard__change {
    cursor: pointer;
  }
}
.animate__img {
  animation-name: slidingstep1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
@keyframes slidingstep1 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.05;
  }
  10% {
    opacity: 0.1;
  }
  15% {
    opacity: 0.15;
  }
  20% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.3;
  }
  35% {
    opacity: 0.35;
  }
  40% {
    opacity: 0.4;
  }
  45% {
    opacity: 0.45;
  }
  50% {
    opacity: 0.5;
  }
  55% {
    opacity: 0.55;
  }
  60% {
    opacity: 0.6;
  }
  65% {
    opacity: 0.65;
  }
  70% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.75;
  }
  80% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.85;
  }
  90% {
    opacity: 0.9;
  }
  95% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  .usercard {
    width: 300px;
    // height: 250px;
    margin-top: 0;
  }
}
