.iframe {
  width: 100%;
  height: 85vh;
}

.modal__modal {
  width: 100%;
  // background-color: transparent;
  padding: 0;
  margin: auto;
}

.modal__overlay {
  //   border: solid red 1px;
}

.modelContent {
  overflow-x: auto;
  width: max-content;
  margin: auto;
  max-width: 100%;
}
.close__modal__container {
  margin-top: 50px;
}
.close__modal__icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .close__modal__container {
    margin-top: 0px;
  }
  .close__modal__icon {
    display: block;
    width: max-content;
    margin-left: auto;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    margin-right: 10px;
    // padding: 5px 10px;
  }
  .close__modal__icon:before {
    content: "\2573"; // here is your X(cross) sign.
    color: black;
    font-weight: 700;
    font-size: 1em;
    font-family: Arial, sans-serif;
    margin-right: 10px;
    // border: solid red 1px;
  }
}
