.newsUpdate {
  // font-size: small;
  width: 100%;
  img {
    width: 100%;
  }
  p {
    width: 100%;
  }
  h3 {
    font-weight: bold;
    
  }
  .newsUpdate__header{
  color: #ef3439;
    font-size: 1.5em;
  }
  .newsUpdate__content {
    margin-bottom: 40px;
    p:first-of-type {
      color: #ef3439;
    }

    h5 {
      color: #ef3439;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 768px) {
  .newsUpdate {
    width: 250px;
    height: auto;
    h3 {
      margin-top: 0;
    }
  }
}
