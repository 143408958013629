.signup {
  display: flex;
  flex-direction: column;
  color: #000000;
  & > div {
    height: 250px;
    background: url("../../assets/img/3.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  .signup__logo {
    img {
      cursor: pointer;
      height: 50px;
      margin: 20px;
    }
  }
  & > form {
    width: 100%;
    padding: 0 10%;
    position: relative;
    top: -20px;
    background-color: white;
    border-radius: 20px;
  }
  h3 {
    text-align: center;
    font-size: 1.4em;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: normal;
    margin-bottom: 40px 0;
  }
  p {
    font-size: 0.8em;
    text-align: center;
    margin-bottom: 30px;
  }
}
.noShow {
  display: none;
}
.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  span {
    cursor: pointer;
    font-weight: bold;
    // border: solid red 1px;
  }
  i {
    font-size: 15px;
    margin-right: 3px;
    // border: solid red 1px;
    position: relative;
    top: -2px;
  }
  button {
    background: #ef3439 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    color: white;
    border: none;
    outline: none;
    font-size: 1em;
    margin-left: auto;
    padding: 7px 20px;
    justify-self: right;
    cursor: pointer;
  }
}
.disable {
  opacity: 0.6 !important;
  cursor: text;
}

@media screen and (min-width: 768px) {
  .signup {
    flex-direction: row;
    align-items: center;
    padding: 0;
    height: 100vh;

    & > div {
      flex-grow: 0;
      flex-basis: 50%;
      background: url("../../assets/img/3.png");
      background-repeat: repeat;
      background-size: 100% 100%;
      background-position: center;
      height: 100%;
    }

    & > form {
      flex-basis: 50%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 0;
      background-color: white;
      border-radius: 0;
    }
  }
  .buttonGroup {
    width: 100%;
  }
}
