.resources__more {
  color: #ef3439;
}
.resources__details {
  border-bottom: solid 2px #ef3439;
  margin-bottom: 25px;
  p {
    // font-size: small;
   
  }
  h5 {
    margin-bottom: 10px;
  }
}

.resources {
  margin: 15px 10px 16px 10px;
  border-radius: 10px;
  padding: 21px;
  background-color: white;
}
.resources__footer {
  color: #ef3439;
  font-weight: bold;
  font-size: small;
  span {
    margin-right: 10px;
    cursor: pointer;
  }
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .resources__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: unset;
    padding-right: 30px;
    padding-left: 20px;
    // border: solid red 1px;
    margin: 0;
    margin-bottom: 5px;
    justify-content: space-between;

    p {
      color: #1e1e1e;
      font-size: 1em;
      margin: 0;
      margin-bottom: 10px;
    }
    
    h5 {
      margin: 0;
      cursor: pointer;
    }
  }
  .resources__details:not(:last-of-type) {
    border-right: solid 2px #ef3439;
  }
  .resources__details:first-of-type {
    padding-left: 0;
  }

  .resources {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin: 0;
    margin-bottom: 5px;
    padding: 10px 20px 5px;
    // border: solid red 1px;

    max-height: 350px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;

    .resources__footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: small;
      flex-grow: 1;
      // border: solid red 1px;
    }

    .resources__main {
      display: flex;
      flex-grow: 3;
      & > div {
        // flex-basis: 0;
        // flex: 1 1 0px;
      }
    }

    .resources__title {
      margin: 0;
      // border: solid red 1px;
      flex-grow: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-height: 800px) {
  .resources__details {
    p {
      font-size: 1.3em;
      // border: solid red 1px;
    }
  }
}
