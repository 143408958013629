.sector__breadcrumb {
  font-size: xx-large;
  color: #1e1e1e;
  margin: 16px 0;

  span:first-of-type {
    font-weight: bold;
  }
}
.sector {
  padding: 0 20px;
  p {
    margin-bottom: 40px;
    font-size: 0.95em;
    // border: solid red 1px;
  }

  .sector__gallery {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .sector__breadcrumb {
    font-size: xx-large;
    color: #1e1e1e;
    margin: 0;
    margin-bottom: 16px;

    span:first-of-type {
      font-weight: bold;
    }
  }

  .sector {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 85px);
    padding: 0;

    p {
      margin-bottom: 5vh;
      width: 85%;
    }
  }
  .sector__gallery {
    max-width: 1200px;
    margin: 0 auto;
  }
}
