.signin {
  & > div {
    height: 250px;
    background: url("../../assets/img/1.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
.signin__logo {
  img {
    cursor: pointer;
    width: auto;
    height: 50px;
    margin: 20px;
  }
}

.signin__heading {
  margin-top: 50px;
}
.red {
  color: #ef3439;
  // border: solid red 1px;
}
.left__align {
  position: relative;
  left: -20px;
}
.signin__others {
  width: max-content;
  margin: 0;
}
.form__forgotpassword {
  margin-left: 25px;
  font-size: small;
  color: #ef3439;
  font-weight: bold;
  cursor: pointer;
}

.forgotpassword__modal {
  margin: 30px 20px;
  min-width: 250px;

  h3 {
    margin-bottom: 45px;
  }

  button {
    padding: 10px 20px;
    border: 0;
    outline: 0;
    border-radius: 50px;
    font-weight: bold;
    background-color: #ef3439;
    color: white;
    margin-top: 30px;
  }
}

.recovery__email {
  // border: solid red 1px;

  .disabled {
    opacity: 0.5;
  }

  .isDirty {
    border-color: #ef3439;
  }
  button {
    padding: 15px 20px;
    border: 0;
    outline: 0;
    border-radius: 50px;
    font-weight: bold;
    background-color: #ef3439;
    color: white;
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .signin {
    height: 100vh;

    & > div {
      background: url("../../assets/img/1.png");
      background-attachment: fixed;
      background-repeat: round;
      background-size: auto 100%;
      height: 100%;
    }
  }
  .signin__main {
    width: 100%;
    max-width: 600px;
  }
}
