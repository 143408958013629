.fullcard {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 50px;
  width: 90%;
  padding: 10px;
  max-width: 350px;
  color: #1e1e1e;
  img {
    width: 100%;
  }
  h2 {
    font-size: 1.7em;
  }
  p {
    font-size: 0.9em;
    line-height: 1.6;
  }
  p:first-of-type{
    color:#ef3439;
    margin:0;
  }
}

.fullcard:hover {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;

  & h2 {
    color: #ef3439;
  }
}

@media screen and (min-width: 768px) {
  .fullcard {
    flex-direction: row;
    width: 80%;
    max-width: 1800px;
    margin-bottom: 90px;
    align-items: center;
    justify-content: center;

    img {
      height: auto;
      max-height: 270px;

    }
    div {
      flex-basis: 50%;
    }
  }
  .fullcard__details {
    padding: 0 30px;
    display: flex;
    flex-direction: column;

   
   h2{
     margin-top:0;
   }
   p:last-of-type{
     margin-bottom: 0;
   }
  }
}
