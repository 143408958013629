.showlist {
  display: flex;
  flex-direction: column;
  .showlist__main {
    span {
      display: block;
      margin-bottom: 5px;
    }
  }
  .showlist__footer {
    color: #ef3439;
    font-weight: bold;
    word-wrap: break-word;
  }
  margin-bottom: 20px;
}
