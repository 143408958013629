@import "../../assets/styles/colors";

.footer {
  background-color: black;
  margin-top: 150px;
  color: white;
}
.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  padding: 40px 10px;
  margin: 0 auto;
  border-bottom: solid #a6a6a6 1px;
}

.category {
  & > * {
    display: block;
    margin: 7px 0;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 51%, $accent 51%);
    background-position: 0%;
    transition: background-position 1s;
    padding: 5px;
    cursor: pointer;
  }

  font-size: 0.89em;
  color: #a6a6a6;
  line-height: 1.3;
  text-align: center;
  padding: 5px;

  span:first-child {
    font-weight: 700;
    font-style: normal;
    font-size: 1.0714285714em;
    line-height: 1.33;
    margin-bottom: 30px;
    color: white;
  }
  max-width: 80%;
}

.others {
  & > * {
    display: block;
    color: #a6a6a6;
    margin: 20px 0;
  }
  font-size: 0.57em;
  letter-spacing: 2px;
  text-align: center;
  padding: 30px 0;
  width: 95%;
  margin: 0 auto;
  .icons__list {
    font-size: 2.85em;
    & > * {
      margin-right: 15px;
    }
  }
}

.categories span:not(:first-child):hover {
  background-position: -100% -100%;
  color: white;
}

@media screen and (min-width: 768px) {
  .categories {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 95%;
    padding: 40px 10px;
    margin: 0 auto;
    border-bottom: solid #a6a6a6 1px;
  }
  .category {
    max-width: 290px;
    text-align: left;
    & > * {
      float: left;
      clear: left;
    }
  }
}
