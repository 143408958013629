@mixin hover() {
  background-color: white;
  font-weight: bold;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.dsb {
  display: none;
  .dsb__logo {
    cursor: pointer;
    // border: solid red 1px;
    width: 60%;
  }
}

.feedbackModal {
  background: transparent;
}

@media screen and (min-width: 1024px) {
  .dsb {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 220px;
    background-color: #f3eeee;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    .dsb__logo {
      width: 180px;
      height: auto;
      margin: 10px auto 0;
    }
    .dsb__options {
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > .dsb__option {
        margin: 16.5px auto;
        width: 100%;

        & > div {
          width: 150px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          padding: 7px 0;
        }
      }
    }

    .dsb__footer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // border: solid red 1px;
      .logout {
        color: #ef3439;
        font-weight: bold;
      }

      & > .footer__item {
        margin: 9.5px 0;

        & > div {
          width: 150px;
          margin: 0 auto;
          display: flex;
          align-items: center;
        }
        & > div:not(:last-of-type) {
          padding: 7px 0;
        }
      }
      & > .footer__item:last-of-type {
        margin-bottom: 10px;
        margin-top: 25px;
      }
    }
  }

  .image__icon {
    width: 20px;
    margin-right: 11.6px;
  }

  .footer__item:hover {
    cursor: pointer;
    color: #ef3439;
    // @include hover;
  }
  .dsb__option:hover {
    cursor: pointer;
    color: #ef3439;

    // @include hover;
  }
}

.activeSidebar {
  @include hover;
}
