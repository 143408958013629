/* Customize the label (the checkbox) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 58px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.9em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 20px;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: solid 1px black;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #ef3439 !important;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  //   left: 9px;
  //   top: 5px;
  //   width: 5px;
  //   height: 10px;
  //   border: solid white;
  //   border-width: 0 3px 3px 0;
  //   -webkit-transform: rotate(45deg);
  //   -ms-transform: rotate(45deg);
  //   transform: rotate(45deg);
}
